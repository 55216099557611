.container {
  font-size: 0.9rem;
}
.filterContainer {
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
  text-align: start;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  a, div{
    margin:7px 0px;
  }
}
.jobListMenuTitle{
  font-size: 1.3rem;
  text-align: center;
}
.alertButton{
  font-weight: 700 !important;
  padding-left: 14px;
  width: 173px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.openApplicationButton{
  font-weight: 700 !important;
  height: 44px;
  width: 173px;
}
@media only screen and (max-width: 600px) {
  .filterContainer {
    margin-bottom: 1rem;
  }
}
