@import "../../styles/colors.scss";

.footer {
  padding: 2rem;
  border-top: 1px solid $border-colour !important;
  background-color: black;
  margin-top: 4rem;
  font-size: 0.9rem;
  .logo {
    height: 30px;
    width: auto;
  }
  a {
    color: white !important;
  }
  a:hover {
    color: $brand-color;
  }
}

.wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.wrap a:last-child {
  margin-left: auto;
}
