.special-label {
    display: none;
}
.form-control {
    font-size: 1rem !important;
    height: auto !important;
    width: 100% !important;
    line-height: 33px !important;
}
.number-input-valid {
    border-color: var(--bs-form-valid-border-color) !important;
    border-left: 0 !important;
    box-shadow: none !important;

    padding-right: calc(1.5em + .75rem) !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E") !important;
    background-repeat: no-repeat !important;
    background-position: right calc(.375em + .1875rem) center !important;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem) !important;
}
.number-input-invalid {
    border-color: var(--bs-form-invalid-border-color) !important;
    border-left: 0 !important;
    box-shadow: none !important;
    
    padding-right: calc(1.5em + .75rem) !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e") !important;
    background-repeat: no-repeat !important;
    background-position: right calc(.375em + .1875rem) center !important;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem) !important;
}
.prefix-input-valid{
    border-color: var(--bs-form-valid-border-color) !important;
    border-left: 0 !important;
}
.prefix-input-invalid{
    border-color: var(--bs-form-invalid-border-color) !important;
    border-left: 0 !important;
}
.button-valid{
    border-color: var(--bs-form-valid-border-color) !important;
    border-right: 0 !important;
}
.button-invalid{
    border-color: var(--bs-form-invalid-border-color) !important;
    border-right: 0 !important;
}
.phone-input-group-valid {
    &:focus-within{
        box-shadow: 0 0 0 .25rem rgba(var(--bs-success-rgb), .25) !important;
    }
}
.phone-input-group-invalid {
    &:focus-within{
        box-shadow: 0 0 0 .25rem rgba(var(--bs-danger-rgb), .25) !important;
    }
}
#prefix-input{
    width: 6.8rem !important;
    border-radius: 5px 0 0 5px;
    border-right: 0;
    text-align: center;
    cursor: default;
}
#phone-input{
    flex-grow: 1;
    border-radius: 0 5px 5px 0;
}
#phone-input-group{
  display: flex; 
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px !important;
}
.react-tel-input{
    width: 6.8rem !important;
}
.test-input{
    width: 100% !important;
}

#phone-input::-webkit-outer-spin-button,
#phone-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
#phone-input {
    -moz-appearance: textfield;
}