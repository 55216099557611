@import "../../styles/colors.scss";

.header {
  height: 500px;
  max-height: 500px;
  background-image: url("../../assets/viewCareerImage.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  text-align: center;
  padding: 1.1rem 4rem;
  .logo {
    height: 40px;
    text-align: left;
    display: block;
    width: 100%;
  }
  a {
    color: white !important;
  }
  a:hover {
    color: $brand-color;
  }
}
.content {
  margin-top: 2rem;
}
a.company {
  border: 1px solid $brand-color !important;
  padding: 0 2rem !important;
  color: $brand-color !important;
}
