@import "../../../styles/colors.scss";

.container {
  padding: 16px;
  border: 1px solid #f2f4f9;
  margin-bottom: 2rem;
  font-size: 0.1rem !important;
}

.left {
  border-right: 1px solid #f2f4f9;
  margin-right: 1rem;
}

.detailsSection {
  margin-bottom: 0.5rem;
  font-size: 0.8rem !important;
}
.splitter {
  font-size: 0.6rem;
  font-weight: 900;
}
.section {
  color: $brand-color;
  font-weight: 500;
  margin-top: 0.8rem;
  margin-bottom: 0.2rem;
}
.linedinLink {
  font-size: 0.9rem;
  font-weight: 500;
  color: $linkedin !important;
}
