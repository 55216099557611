@import "../../../styles/colors.scss";
@import "../../../styles/defaults.scss";

.container {
  border-bottom: 1px solid #E5E5E5;
  text-align: left;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

.title {
  color: $brand-color;
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.feature {
  margin-right: 2rem;
  font-size: 0.8rem !important;
  font-weight: 500;
  color: $secondary-text-color;
  img {
    margin-right: 0.3rem;
  }
}
.description {
  text-align: justify;
  font-weight: 600;
  margin-top: 0.5rem;
  font-size: .9rem;
}
.skill {
  background-color: $faded-background;
  border-radius: $border-radius-rounded;
  padding: 0.3rem 1rem;
  margin-right: 0.5rem;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  display: inline-block;
}
