.chip {
    background: #1da148 !important;
    color: white !important;
  }
  .highlightOption {
    background: #e6e6e6 !important;
    color: #212529 !important;
  }
  .option:hover {
    background: #1da148 !important;
    color: white !important;
  }
  .option {
    background: #e6e6e6;
  }
  .notFound {
    background: #e6e6e6;
  }
  .groupHeading {
    background: #e6e6e6;
    font-weight: 700;
  }
#recurrence {
  width: calc(12rem + 20px);
}
.multiselectInvalid.showValidation .search-wrapper{
  border-color: var(--bs-form-invalid-border-color) !important;
  padding-right: calc(1.5em + .75rem) !important;
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e') !important;
  background-repeat: no-repeat !important;
  background-position: right calc(.375em + .1875rem) center !important;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem) !important;
}

.multiselectValid.showValidation .search-wrapper{
  border-color: var(--bs-form-valid-border-color) !important;
  padding-right: calc(1.5em + .75rem) !important;
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23198754%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e') !important;
  background-repeat: no-repeat !important;
  background-position: right calc(.375em + .1875rem) center !important;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem) !important;
}
#recurrence .search-wrapper{
  background-position: right calc(.375em + .1875rem + 20px) center !important;
}