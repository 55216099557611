@import "../src/styles/colors.scss";
@import "../src/styles/defaults.scss";

a.brand {
  color: #fff !important;
}
a.brand:hover {
  border-bottom: 3px solid $brand-color !important;
}
a {
  text-decoration: none !important;
  border-bottom: 3px solid transparent !important;
  cursor: pointer !important;
  display: flex;
  align-items: center;
  color: $link-color !important;
}
a.dark {
  color: #000 !important;
}
a.nohover {
  border-bottom: 3px solid transparent !important;
}
button {
  background-color: $brand-color;
  border-color: $brand-color;
  color: white;
  border: 1px solid $brand-color;
  border-radius: 0;
  padding: 5px 20px;
}
button.secondary {
  background-color: transparent;
  border: 1px solid white;
  color: white;
  border-radius: 0;
  padding: 10px 25px;
}
button.third {
  background-color: transparent;
  border: 1px solid $secondary-text-color;
  color: $secondary-text-color;
  border-radius: 0;
}
h1 {
  font-size: calc(2.575rem + 1.5vw) !important;
}
h2 {
  color: $text-color;
  margin-bottom: 16px;
  font-size: 1.5rem !important;
  font-weight: 700;
}
h3 {
  color: $text-color;
  margin-bottom: 16px;
  font-size: 1.17rem !important;
  font-weight: 700;
}

h4 {
  color: $text-color;
  margin-bottom: 16px;
  font-size: 1.125rem;
  font-weight: 700;
}
textarea,
input[type="text"],
input[type="email"] {
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  line-height: 33px;
  outline: none !important;
  resize: none !important;
}
input[type="text"]:focus {
  box-shadow: none;
  border-color: #2980b9;
}
input[type="checkbox"] {
  margin-right: 0.5rem;
}
input[type="file"] {
  box-shadow: none;
  border-color: #2980b9;
  background-color: #f3fff6;
  border: 1px dashed #86ca94;
  line-height: 33px;
}
.form-label {
  color: #5c6575;
}
.big-top-margin {
  margin-top: 6rem;
}
.active-route {
  border-bottom: 3px solid $brand-color !important;
}
::placeholder {
  color: #c1c1c1 !important;
  opacity: 1;
}
@media only screen and (max-width: 600px) {
  .noPadding {
    padding: 0 !important;
  }
  .big-top-margin {
    margin-top: 2rem;
  }
}
.text-justify {
  text-align: justify;
}
.required {
  border: 1px solid red;
  border-radius: $border-radius;
  padding: 1rem;
}
