h3{
    color: #1da148 !important;
}
h4{
    font-size: 1rem !important;
}
.js{
    margin-bottom: 2rem;
    font-size: 0.8rem !important;
    li {
      margin-top: 0.25rem !important;
      text-align: justify !important;
      word-wrap: break-word !important;
      word-break: break-word !important;
    }
    p {
        text-align: justify !important;
    }
    a {
        border-bottom: 3px solid transparent !important;
        display: inline-block !important;
    }
}
.panelTitle{
    display: flex;
    justify-content: flex-start;
    align-items: center;  
    flex-direction: row;
}
.leftPanel{
    max-width: 269px !important;
}
img {
    width: 32px;
    height: 32px;
}
.jdCountry{
    font-weight: 600;
    align-items: flex-end;
    height: 20px !important;
}
.cityIcon{
    width: 0px;
    height: 11px;
    margin-left: 25px;
    margin-right: 7px;
}
.globeImage{
    position: relative;
    top: 10px;
}
.cityName{
    font-size: 0.8rem !important;
    font-weight: 400;
}
.location{
    margin-bottom: 10px;
}