@import "../../styles/colors.scss";

.container {
  font-size: 0.9rem;
}
.splitter {
  font-weight: 600;
  color: dimgray;
}
.link {
  color: $link-color !important;
  font-weight: 500;
  display: inline-block;
}
