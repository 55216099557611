.offlineContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
}
.maintenanceTitle {
    color: #212529 !important;
    margin-bottom: 2rem;
    font-size: 3rem !important;
}
.maintenanceParagraph{
    display: block;
    margin-top: 2rem;
    font-size: 1.2rem;
    width: 60%;
    min-width: 30rem !important;
}
.maintenanceImage{
    width: 10rem;
    height: 10rem;
}