.container {
  font-size: 0.9rem;
}
.group {
  color: #333333;
  margin-bottom: 8px;
  font-weight: 700 !important;
  text-transform: capitalize;
  font-size: 0.9rem !important;
}
.filterSection {
  padding: 16px 0 12px;
  border-top: 1px solid #f2f4f9;
}
.filterSection:nth-of-type(1) {
  padding-top: 0;
  border-top: none !important;
}
.filterCriteria {
  padding: 0.2rem;
  p {
    font-size: 0.9rem !important;
  }
}
.filterContainer {
  border: 1px solid #f2f4f9;
  text-align: start;
  padding: 20px;
}
.modifiedAccordion {
  border: none;
  padding: 0 !important;
  button {
    display: none;
  }
}
.modifiedAccordionHeader {
  margin-bottom: 1rem;
}
@media only screen and (max-width: 600px) {
  .modifiedAccordion {
    margin-bottom: 1rem;
    button {
      display: block;
      border: none !important;
      border-color: transparent;
      box-shadow: none !important;
      background-color: transparent !important;
    }
  }
}
