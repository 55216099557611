@import "../../styles/colors.scss";

.npsScale {
  background-color: transparent;
  color: $brand-color;
  border: 1px dashed $brand-color;
}

.npsScaleSelected {
  background-color: $faded-background;
  border-style: solid;
}
.logo {
  height: 40px;
  width: 180px;
}
