.page-link {
    border-bottom: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color) !important;
}

 a{
    border-bottom: 0px !important;
 }

 .page-link {
    color: var(--bs-body-color) !important;
 }

 .active>.page-link{
    color: white !important;
    background-color: #1da148 !important;
    border-color: #1da148 !important;
 }