@import "../../../styles/defaults.scss";
@import "../../../styles/colors.scss";

.innerButton {
  position: absolute;
  border: none;
  background-color: transparent;
  left: calc(100% - 40px);
  z-index: 99999;
  color: $faded-background;
  font-size: $small-font;
  margin-top: 3px;
}
.input {
  line-height: 15px !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  max-width: 50%;
}
.searchButton {
  width: 50px;
  padding: 0;
}
